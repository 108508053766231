/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./layout.css"
import Head from "./head";
import Footer from "./footer";

const Layout = ({ children }) => {
	return (
		<>
			<Head />
			<div
			>
				<main style={{ overflow: "hidden" }}>{children}</main>
				<Footer />
			</div>
		</>
	)
}

export default Layout
