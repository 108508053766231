import styled from "@emotion/styled";

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`;

export { Container };
