/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Apple57 from "../images/favicon/apple-icon-57x57.png";
import Apple60 from "../images/favicon/apple-icon-60x60.png";
import Apple72 from "../images/favicon/apple-icon-72x72.png";
import Apple76 from "../images/favicon/apple-icon-76x76.png";
import Apple114 from "../images/favicon/apple-icon-114x114.png";
import Apple120 from "../images/favicon/apple-icon-120x120.png";
import Apple144 from "../images/favicon/apple-icon-144x144.png";
import Apple152 from "../images/favicon/apple-icon-152x152.png";
import Apple180 from "../images/favicon/apple-icon-180x180.png";
import Favicon32 from "../images/favicon/favicon-32x32.png";
import Favicon96 from "../images/favicon/favicon-96x96.png";
import Favicon16 from "../images/favicon/favicon-16x16.png";

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	);

	const metaDescription = description || site.siteMetadata.description;
	const metaTitle = title || site.siteMetadata.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		>
			<link
				rel="apple-touch-icon"
				sizes="57x57"
				href={Apple57}
			/>
			<link
				rel="apple-touch-icon"
				sizes="60x60"
				href={Apple60}
			/>
			<link
				rel="apple-touch-icon"
				sizes="72x72"
				href={Apple72}
			/>
			<link
				rel="apple-touch-icon"
				sizes="76x76"
				href={Apple76}
			/>
			<link
				rel="apple-touch-icon"
				sizes="114x114"
				href={Apple114}
			/>
			<link
				rel="apple-touch-icon"
				sizes="120x120"
				href={Apple120}
			/>
			<link
				rel="apple-touch-icon"
				sizes="144x144"
				href={Apple144}
			/>
			<link
				rel="apple-touch-icon"
				sizes="152x152"
				href={Apple152}
			/>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={Apple180}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href={Favicon32}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="96x96"
				href={Favicon96}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href={Favicon16}
			/>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `de`,
	meta: [],
	description: ``,
};
export default SEO;
