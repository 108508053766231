import Helmet from "react-helmet"
import React from "react"

const Head = () => (
	<Helmet>
		<link href="https://fonts.googleapis.com/css?family=Indie+Flower" rel="stylesheet" />
		<link href="https://fonts.googleapis.com/css?family=Didact+Gothic" rel="stylesheet" />
	</Helmet>
)

export default Head
