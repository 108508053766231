import React from "react"
import "./footer.css"
import { Container } from "./container";

const Footer = () => {
	return (

		<footer className="footer">
			<Container>
				© {new Date().getFullYear()} All rights reserved. |  <a href="https://www.panca.ch">Dein fair hergestellter Garshana Handschuh</a> |  <a href="mailto:info@panca.ch">info@panca.ch</a>
			</Container>
		</footer>
	)
}

export default Footer
